import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import Iframe from 'react-iframe';
import { BsX } from "react-icons/bs";

export default function App() {
  const isBackgroundDark=false;

  return (
      <Router>
          <Switch>
            <Route path="/film">
              <BackgroundImage/>
              <CloseButton/>
              <Film />
            </Route>
            <Route path="/learnings">
              <BackgroundImage/>
              <CloseButton dark/>
              <Learnings />
            </Route>
            <Route path="/participants">
              <People />
            </Route>
            <Route path="/">
              <BackgroundImage/>
              <Home />
            </Route>
          </Switch>
      </Router>
  );
}

function BackgroundImage() {
  return <div className="BackgroundImage"></div>
}

function CloseButton({dark}) {
  return <Link to="/"><BsX className={`close ${dark ? 'dark' : undefined}`}/></Link>
}

function Home() {
  return (
    <>
    <div className="blankbg"></div>

    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '90vh' }}>
      <div className="homeAgain">
        
        <p class="italics">
  Here you find the documentation of the project Open Expression Support. It's a dreamed up public service supporting anyone in finding their creative expression. Because it’s meaningful and it feels good. We dance, we challenge, we play, we confront, we participate, we make, we act, we stumble and we are. 
  This project comes from a love for learning and figuring things out. 
        </p>
        <p>
  Watch the reflective <Link to="/film">film</Link> with anecdotes of participants and practitioners, <br></br>
  browse through the key <Link to="/learnings">learnings</Link> on supporting creative expression, <br></br>
  dig into the <a href="http://openexpression.support/blog" target="_blank">process blog</a> with session reports, maps and exercises <br></br>
  or read the <a href="./context.pdf" target="_blank">argumentation</a> as to why this project matters. 
        </p>
        <p>
          Good day!
        </p>
      </div>
    </div>
    
</>
  );
}

function Film() {
  return (
    <>
      <section className="darkbg"></section>
      <h2 className="FilmHeader">The OES Film</h2>
      <div className="video-container">
        <iframe class="video" src="https://www.youtube-nocookie.com/embed/MPw7MuGAQo8" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  );
}

function Learnings() {

  let { path, url } = useRouteMatch();

  const learningsCategoryList = Object.keys(realLearnings).map((p)=>

      <NavLink to={`${url}/${p}`} className="learningCategory" activeClassName="selectedLearningCategory">
        {p}
      </NavLink>


  );

  return (
    <>
    <div className="blankbg"></div>
    
      <div className="learningsheader">
        <h2 id="learningstitle"><Link to="/learnings" className="hiddenLink">Thirty-three learnings on supporting creative expression.</Link></h2>
        <div className="learningsFilters">
            {learningsCategoryList}
        </div>
      </div>

      <Switch>
      <Route exact path={path}>
        <div className = "grid">
          <LearningsCards />
        </div>
      </Route>
        <Route path={`${path}/:category`}>
          <div className = "grid">
            <LearningsCards />
          </div>
        </Route>
      </Switch>
  </>    

  );
}

function LearningsCards() {
  let { category } = useParams();

  let renderLearning = function(learning) {
    let footnote;
    if (learning.split('|').length>0) {
      footnote = <div className="learningFootnote">{learning.split('|')[1]}</div>;
    }

    return <div className="learning">
      {learning.split('|')[0]}
      {footnote}
    </div>
  }

  const fullLearningsList = Object.keys(realLearnings).map((cat)=>realLearnings[cat].map(renderLearning));

  if (category == undefined) {
    //if no filter is selected
    Object.keys(realLearnings).map((cat)=>shuffleArray(realLearnings[cat]));
    return <>{fullLearningsList}</>;
  } else {
    shuffleArray(realLearnings[category]);
    return <>{realLearnings[category].map(renderLearning)}</>;
  }

}



const realLearnings = {
  "basic beliefs":
  [
    `Engaging in creative expression is a slow and developmental process with everyone at a different stage.`,
    `Engaging in creative expression is meaningful and feels good.`,
    `Engaging in creative expression is intense, restless and impatient.`,
    `Creative expression takes place in a personal visual and expressive culture.`,
    `Oscillation* is the natural order of the world.
    |*The constant swinging between two seemingly opposite states. A principle borrowed from the metamodern manifesto by Luke Turner.`,
  ],
  "personal":
  [
    `Seek the limits of openness but trust your own boundaries.`,
    `Find and consult your oracle.`,
    `The buffet* of possibilities you offer depends on what you can cook.
    |*Different modes of expression you can support others with (writing, dancing, cooking, …)`,
    `Acknowledge what others already possess.`,
  ],
  "structure":
  [
    `What is the best next thing to do?`,
    `Capture an artefact* of the process that can be shared with friends and family.
    |*A thing someone created. Sharing keeps you going.`,
    `To leave the preparation behind, you have to be prepared first.`,
    `Loose sheets are less intimidating than a Moleskine.`,
    `Naivety is a valid source of inspiration.`,
    `Relate it to your everyday observations and share what has touched you.`,
    `Something arises in endless repetition.`,
    `Only one brush and two colours.`,
    `Reflect on each meeting in a voice message when it’s still fresh.
    |Keeping track of what is happening is even more important when doing an intuitive process.`
  ],
  "social":
  [
    `Creativity is always relational.`,
    `Ask the other: How will we be with each other?`,
    `(Co)creative relationships develop over time.`,
    `Individual attention can be quite intimidating.
    |Groups can be very intimidating too.`,
    `If things get emotional, stay put and let it be.`,
    `Meander together across the boundaries of the comfort zone.`,
  ],
  "body":
  [
    `Start the session with body movement.`,
    `Take your shoes off, walk around, shake into the day.`,
    `Don’t take it too seriously. Find ways to get silly.`,
    `Expect resistance if you want to move with others.
    |It’s too easy to just sit down, talk and write.`,
    `Leave (the) room to the other. Come back to see what happened.
    |For some, guidance inhibits body movement. But it might come naturally if given the space.`,
  ],
  "time & space":
  [
    `Allow enough* time to rest and digest.
    |*Leave 1 or even 2 weeks between sessions.`,
    `Alternate a sense of urgency with the feeling of endless time.
    |Time-boxed exercises create momentary freedom and get you started. But dance like you have all the time in the world.`,
    `Start with an empty space.`,
    `Go outside and listen. Work with what is there.`,
  ]
};












function Context() {
  return <p>(the pdf)</p>;
}

function People() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();

  const participants = ['T','A','M','I','C','O','R'];

  const participantsList = participants.map((p)=>
    <NavLink to={`${url}/${p}`} activeClassName="selectedParticipant">
      <img src={'/maps/PORTRAITS/'+p.toString()+'.jpg'} />
    </NavLink>
  );

  return (
    <div className="justAWhiteBG">
      <ul>
        <nav>
          {participantsList}
        </nav>
      </ul>

      <Switch>
        <Route exact path={path}>
          <p>Select a self-portrait, scroll down and to the right to view their process.</p>
          <footer><a href="https://docs.google.com/forms/d/1AIpR5mbFsRp00ZsVioxCIqVw1T1RyZhj5k12dFCWUo0/viewanalytics" target="_blank">see questionnaire responses</a></footer>
        </Route>
        <Route path={`${path}/:person`}>
          <Mapa />
        </Route>
      </Switch>
    </div>
  );
}

function Mapa() {
  // The <Route> that rendered this component has a
  // path of `/topics/:person`. The `:person` portion
  // of the URL indicates a placeholder that we can
  // get from `useParams()`.
  let { person } = useParams();

  var media = {
    A: [
      `1_When_I_tell_others_what_I_like_to_do, something_moves_inside_of_me.mp3`,
      `2_Namen_tanzen.mp3`,
      `3_Die_Letzten_Sonnenstralen.mp3`,
      `3_Session_recap.mp4`,
      `3_Sharing_the_work_felt_good.mp3`,
      `4_Session_Recap.mp4`,
      `5_You_couldn't_have_planned_it.mp3`,
      `6_Eierplätzchen_character_interview.jpg`,
      `6_If_you_spend_time_doing_what_you_love.mp3`,
      `7_Self-composition.mp3`,
      `7_Self-portrait.mp4`,
    ],
    C: [
      `1_Wisdom_in_my_body.mp3`,
      `2_I_tend_to_take_over.mp3`,
      `3_Stand_there_and_see_what_happens.mp3`,
      `4_A_little_bit_too_much.mp3`,
      `6_About_KISD.mp3`,
    ],
    I: [
      `3_Recapping_Session.mp4`,
      `3_Voice-memo_about_session.mp3`,
      `4_Recapping_Session.mp4`,
      `5_Narrating.mp4`,
      `6_Character_interview.mp3`,
      `6_Place_for_character_interview.jpg`,
      `7_Drawing_self-portrait.jpg`,
    ],
    M: [
      `1_Schicksahl.mp3`,
      `2_About_talking_in_the_mic.mp3`,
      `3_On_making_a_song.mp3`,
      `4_You_take_the_leading_position.mp3`,
      `5_Looking_outside_the_window.mp3`,
      `6_Self-portrait.mp4`,
      `7_About_the_process.mp4`,
    ],
    O: [
      `4_Südbrücke.mp4`,
      `5_Drawing_on_pictures.jpg`,
      `5_Pictures_on_floor.jpg`,
    ],
    R: [
      `3_Recapping_session.mp4`,
      `4_Trying_Narration.mp4`,
    ],
    T: [
      `1_Bewegung_und_Vertrauen.mp3`,
      `1_Output_ist_wichtig.mp3`,
      `2_Hands_on_Chest_and_Stomach.mp3`,
      `3_About_the_exercise.mp4`,
      `3_Everything_you_take_into_the_session_is_just_gone.mp3`,
      `4_Feet.mp4`,
      `4_Take_it_not_too_seriously.mp3`,
      `5_Creating_connections_between_body_and_mind.mp3`,
      `6_concerning_the_everyday.mp3`,
      `7_Self-portrait.mp4`,
      `7_self-komposition.mp3`,
    ]
  };

  let mediaData = {
    '1': [],
    '2': [],
    '3': [],
    '4': [],
    '5': [],
    '6': [],
    '7': []
  };

  media[person].map(function(file) {
    let place = file.charAt(0);
    let name = file.substring(2).split('.')[0].replace(/_/g, ' ');
    let fileType = file.split('.')[1];

    mediaData[`${place}`].push({
      "name":name,
      "type":fileType,
      "person":person,
      "url":"/maps/"+person+"/"+file,
    });
  });

        /*<div className="MapsMedia" style={{top:{posY}, left:{posX}}}>*/

  let mediaList = Object.keys(mediaData).map(item => {
    let posY = 53.5+'vmin';
    let posX = 117+(180*(item-1))+'vmin';
    var styles1 = {
      top: posY,
      left: posX,
      /*backgroundColor: 'yellow',*/
    };
    return <div className="MapsMedia" style={styles1}>

            {mediaData[item].map(renderMedia)}
           </div>
  });


  let mapImage = '/maps/MAPS/' + person.toString() + '_Map.jpg';

  return (
    <div className="Maps">
      <img src={mapImage}/>
        {mediaList}
    </div>
  );
}

function renderMedia(el) {
  //let fileName = "/maps/"+el.person+"/"+el.name+"."+el.type;

  let media;

  if (el.type == 'mp3') {
    media = <audio src={el.url} controls />;
  } else if (el.type == 'mp4') {
    media = <video src={el.url} controls />;
  } else if (el.type == 'jpg') {
    media = <img src={el.url} controls />;
  } else {
    media = <p>{el.name}</p>;
  }

  return (
    <>
    {media}
    <figcaption>{el.name}</figcaption>
    </>
  )
}

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}
